import React from 'react'
import {Form,Label} from 'semantic-ui-react'

class PasswordEntry extends React.Component {
  render() {
    return (<Form>
      <Form.Group width='equal'>
        <Form.Field>
          <Label>Enter password to continue:</Label>
          <Form.Input type='password'>Enter Password</Form.Input>
        </Form.Field>
      </Form.Group>
      <Form.Button>Submit</Form.Button>
    </Form>);
  }
}

export default PasswordEntry;