import React from 'react'
import {Button, Form, Grid, Header, Message, Segment} from 'semantic-ui-react'

export default class SaveTheDate extends React.Component {
  render() {
    const value = (
      <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
        <Grid.Column style={{maxWidth: 450}}>
          <Header as='h2' textAlign='center'>
            Save The Date: May 11th, 2020
          </Header>
          <Form size='large'>
            <Segment stacked>
              <Form.Input fluid icon='user' iconPosition='left' placeholder='E-mail address'/>
              <Form.Input fluid icon='user' iconPosition='left' placeholder='Full Name'/>
              <Form.Input fluid icon='user' iconPosition='left'
                          placeholder='Number of Guests (not including yourself)'/>
              <Button fluid size='large'>
                Save The Date
              </Button>
            </Segment>
          </Form>
          <Message>
            Questions? <a href='mailto:jacobrepp@gmail.com'>Feel free to e-mail</a>
          </Message>
        </Grid.Column>
      </Grid>);
    return value;
  }
}
