import React from 'react'
import PasswordEntry from "./components/PasswordEntry";
import {Container, Segment} from 'semantic-ui-react'

export default class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {page: 'main', guest: 'Visitor'};
  }

  componentDidMount() {
  }

  render() {
    return (<Container>
      <h1>Welcome, {this.state.guest}</h1>
      <Segment>
        <PasswordEntry />
      </Segment>
    </Container>);
  }
};