import React  from 'react';
import {Container, Header, Menu} from 'semantic-ui-react'
import { Redirect } from "react-router-dom";

class SiteHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      authToken: null,
      redirectTo: null,
      activeItem: '',
      activeItemPosition: 0,
      menuItems: [
        {text: 'Save the Date', nav: 'save-the-date'},
        {text: 'Welcome', nav: 'welcome'},
        {text: 'Our Story', nav: 'our-story'},
        {text: 'Photos', nav: 'photos'}
      ]
    };
  }

  componentDidMount() {
  }

  handleMenuClick(item) {
    this.props.history.push(item.nav);
  }

  isActive(item) {
    return false;
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>
    }
    return (<Container text>
      <Header
        as='h2'
        content='Repp-Gschwind Wedding' attached/>
      <Menu>
        {this.state.menuItems.map((item) => {
          return (<Menu.Item
              name={item.nav}
              key={item.nav}
              active={this.isActive(item)}
              onClick={(e) => this.handleMenuClick(item)}>
              {item.text}
          </Menu.Item>);
        })}
      </Menu>
    </Container>);
  }
}

export default SiteHeader;