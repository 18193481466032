import React from 'react';
import {Responsive, Image} from "semantic-ui-react";
import {HashRouter, Route} from "react-router-dom";
import SiteHeader from './SiteHeader.js'
import Welcome from './Welcome.js'
import SaveTheDate from './SaveTheDate.js'
import Story from './Story.js'
import './App.css';
import Photos from "./Photos";

export default class App extends React.Component {

  getWidth() {
    const isSSR = typeof window === 'undefined'
    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
  }

  componentDidMount() {
    document.title = 'Wedding';
  }

  render() {
    return (<div className="Wedding">
      <link rel="stylesheet" href="//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css"/>
      <Responsive getWidth={this.getWidth} minWidth={Responsive.onlyMobile.minWidth}>
        <Image src='/drawing.png' />
      </Responsive>
      </div>);
  }

  render_blah() {
    return (
      <div className="App">
        <link rel="stylesheet" href="//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css"/>
        <Responsive getWidth={this.getWidth} minWidth={Responsive.onlyTablet.minWidth}>
          <HashRouter basename="/">
            <SiteHeader mobile={false}/>
            <Route path="/welcome"
                   render={(props) => <Welcome {...props} />} />
            <Route path="/save-the-date"
                   render={(props) => <SaveTheDate {...props} />} />
            <Route path="/our-story"
                   render={(props) => <Story {...props} />} />
             <Route path="/photos"
                   render={(props) => <Photos {...props} />} />
          </HashRouter>
        </Responsive>
      </div>
    );
  }
}
